// Splitting SCSS files per component
@import './AnalyticsDashboard.scss';
@import './CustomPagination.scss';
@import './Preview.scss';
@import './PreviewProductTargeting.scss';
@import './Scheduler.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-slider/src/sass/bootstrap-slider.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'proxinova2';
    src: local('Proxima Nova'), url('../fonts/Proxima\ Nova/proxinova2.woff') format('woff');
}

html, body {
    font-family: "Inter", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    overflow: hidden;
    
    &::-webkit-scrollbar {
        height: 1em;
        width: 1em;
        background: #F9FAFB;
        border-radius: 2px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #E5E7EB;
        border-radius: 2px;

        &:active {
            background: #D1D5DB;
        }
    }
}

@layer utilities {
    .font-poppins {
        font-family: 'Poppins';
    }

    .custom-range {
        &::-moz-range-thumb,
        &::-webkit-slider-thumb {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            background: #ffffff !important;
            border: 1px solid #E5E7EB !important;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08) !important;
            border-radius: 9999px;
            cursor: pointer;
            height: 1.25rem;
            width: 1.25rem;
        }
    
        &::-moz-range-progress,
        &::-webkit-progress-value {
            background: #7E3AF2 !important;
            height: 0.5rem !important;
            border-radius: 9999px !important;
        }
    }

    #help-center-video {
        iframe {
            border-radius: 0.5rem;
        }
    }

    #ab-dashboard-video {
        width: 60% !important;
        height: 25em !important;
    
        iframe {
          border-radius: 8px !important;
        }
    }

    .dashboard-header {
        background: url('../images/LinesDashboardHeader.png'), linear-gradient(91.42deg, #7004B2 3.2%, #9605F0 92.67%);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }

    .dashboard-header-form {
        background: linear-gradient(112.91deg, rgba(255, 255, 255, 0.51) 3.51%, rgba(255, 255, 255, 0.29) 111.71%);
        backdrop-filter: blur(10.3291px);
    }

    .pricing-calc-slider {
        display: none !important;

        .MuiSlider-mark[data-index='0'],
        // .MuiSlider-mark[data-index='3'],
        .MuiSlider-mark[data-index='4'],
        .MuiSlider-mark[data-index='5'],
        .MuiSlider-mark[data-index='6'] {
            display: none;
        }

        .MuiSlider-markLabel[data-index='1'] {
            left: 10% !important;
        }

        .MuiSlider-markLabel[data-index='2'] {
            left: 30% !important;
        }

        .MuiSlider-markLabel[data-index='3'] {
            left: 50% !important;
        }
    }

    .tox-tinymce {
        border-top-right-radius: 0.5rem !important;
        border-top-left-radius: 0.5rem !important;
        border: none !important;
        border-bottom:  1px solid #e5e7eb !important;
    }
}

// :md
@media (min-width: 768px) {
    @layer utilities {

    }
    .pricing-calc-slider {
        display: flex !important;
    }
}