.btn-schedule {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  background: #FFFFFF !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 0.75rem !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: #111827 !important;
  outline: none !important;
  transition: none !important;
  box-shadow: none !important;

  &:hover {
    background: #f9fafb !important;
  }

  &:focus {
    outline: none !important;
    background: #f9fafb !important;
  }

  &::after {
    content: none !important;
  }
}

.calendar-menu {
    position: absolute !important;
}

.calendar-item {
    width: 100%;

    .rdrDateDisplayWrapper {
        border-radius: 0.5rem !important;
        background: white !important;
        
        .rdrDateInput {
            border: 1px solid #e5e7eb !important;
            box-shadow: none !important;
            border-radius: 0.5rem !important;
        }
      
        .rdrDateDisplay {
            margin: 0 !important;
        }
      
        .rdrDateDisplayItem {
            input {
                height: fit-content !important;
                font-size: 0.875rem !important;
                
                &::placeholder {
                    color: #344054 !important;
                    font-weight: 500 !important;
                    font-size: 0.875em !important;
                    line-height: 1.25em !important;
                }
            }
        }
      
        .rdrDateDisplayItemActive {
            background: #f9fafb !important;
            
            input {
                font-size: 0.875rem !important;
                &::placeholder {
                    font-weight: 500 !important;
                    font-size: 0.875em !important;
                    line-height: 1.25em !important;
                    color: #7e22ce !important;
                }
            }
        }
    }
    .rdrDay {
        font-size: 14px;
        color: #101828 !important;
        background: #ffffff !important;
    
        .rdrInRange, .rdrStartEdge, .rdrEndEdge {
            background: #f3e8ff !important;
            border: none !important;
            box-shadow: none !important;
        }
        
        span {
            color: #101828 !important;
            &:after {
                color: #101828 !important;
                background: #f3e8ff !important;
            }
        }
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview {
        border: none !important;
        box-shadow: none !important;
        color: #101828 !important;
    }

    .rdrDayHovered {
        padding: 0.25rem 1rem !important;
        border-radius: 1.042em !important;
        background: #f3e8ff !important;
    }

    .rdrMonth {
        width: 100%;
    }

    .rdrMonthAndYearPickers,
    .rdrMonth {
        font-size: 0.875rem !important;
    }

    .rdrMonthPicker,
    .rdrYearPicker {
        select {
            border-radius: 0.5rem !important;
            
            &:hover {
                background-color: #F9F5FF !important;
            }
        }
    }
}