.custom-pagination {
  align-self: center;

  .MuiPaginationItem-page.Mui-selected {
    background-color: #F9F5FB;
    color: #8A00E0;

    &:hover {
      background-color: #F9F5FB;
      color: #8A00E0;
    }
  }
  
  .MuiPaginationItem-rounded {
    border-radius: 8px;
  }

  .MuiPaginationItem-root {
    color: #667085;
    font-size: 13px;
    background-color: #FFFFFF;
    box-shadow: 0px 0.9506px 2.8518px rgba(16, 24, 40, 0.1), 0px 0.9506px 1.9012px rgba(16, 24, 40, 0.06);
    border: none;

    &:hover {
      background-color: #F9F5FB;
      // color: #8A00E0;
    }
  }
}