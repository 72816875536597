.previewProductSelector_container {
  margin-top: 10px;
  width: 100%;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
  background: #FFFFFF;
  border: 1.5px solid #472EB4;
  box-sizing: border-box;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;

  .previewProductSelector_searchBar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      padding: 7px 10px 7px 10px;
      gap: 5px;
      border-bottom: 1px solid #D4D2DE;

      input {
          border: none;
          width: 80%;
          outline: none;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.3px;
          color: #472EB4;
      }

      span {
          cursor: pointer;

          svg {
              fill: #472EB4;
              stroke: #472EB4;

              circle {
                  fill: transparent;
              }
          }
      }
  }

  .previewProductSelector_tableContainer {
      width: 100%;
      cursor: pointer;    
      padding: 0 0 7px 20px;
      overflow: auto;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
          background: #E5E2FF;
          border-radius: 100px;
      }
      
      th {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          text-align: center;
          color: #9F9F9F;
      }
      
      .previewProductSelector_table{
          height: fit-content;
          width: 100%;
      }

      .previewProductSelector_tableRow {
          width: 100%;

          &:hover {
              background: #F5F5FF;
          }

          .previewProductSelector_productImage{
              border: 1px solid #ECECEC;
              border-radius: 4px;
              width: 40px;
              height: 40px;
              object-fit: cover;
          }

          td {
              min-width: 100px;
              font-weight: 600;
              font-size: 12px;
              color: #333333;
              padding: 3px 5px;
          }

          .td_image {
              min-width: 50px;
          }
      }
  }
}