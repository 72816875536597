.recharts-responsive-container {
  margin-left: -25px;
  overflow: hidden;
}

.recharts-text, 
.recharts-cartesian-axis-tick-value {
  font-size: 10px;
}

.recharts-legend-wrapper,
.recharts-default-legend {
  padding: 0 0 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.recharts-legend-item,
.legend-item-0 {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.recharts-legend-item-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.211198px;
  color: #6F6C99;
}

.recharts-cartesian-axis-line {
  stroke: rgba(170, 170, 170, 0.9);
}

.recharts-cartesian-grid {
  line {
    stroke: rgba(170, 170, 170, 0.15);
  }
}