.previewWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 5px;
}

.previewFrame {
    border: 1px solid #d8d8d8;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
}
  
.previewLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.previewContainer{
    height: 90%;
    width: 100%;
    margin-bottom: auto;
    position: relative;
    overflow:hidden;
}

.previewToolBar {
    display: flex;
    width: 100%;
    height: auto;
    gap: 6px;
    margin-bottom: 10px;

    .dropdown-toggle.btn.btn-primary{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        background: #FFFFFF;
        border: none;
        box-sizing: border-box;
        border-radius: 3px;
        height: auto;
        color: #472eb4;
        align-self: center;
        color: #472eb4;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.3px;
        padding: 7px 10px;
        transition: none !important;

        &:focus, &:active {
            outline: none;
            box-shadow: none !important;
        }
    }

    .dropdown-menu.show {
        transform: translate(0px, 35px) !important;
        border: none !important;
        background-color: transparent !important;
        height: fit-content;
        max-height: 600px;
        overflow: auto;
        min-width: 650px;
    }
}

.selectProductsBtn {
    &:hover {
        .dropdown-toggle.btn.btn-primary {
            background: #472eb4;
            color: #FFFFFF;
        }
    }
}

.PreviewToolbarItem{
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #472eb4;
    box-sizing: border-box;
    border-radius: 7px;
    height: auto;
    color: #472eb4;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.3px;
    padding: 3px 10px;

    .handIcon {
        transform: rotate(-20deg);
        margin-right: 5px;
    }

    &:hover {
        background: #472eb4;
        color: #FFFFFF;
    }
}


.previewSelectorDropdown {
    width:100%;
}

.previewSelectorHover {
    // border: 5px solid red;
    outline: "2px solid red"  !important;
    color: red;
}

.becuaseWrapper {
    background-color: green;
}

.previewProductSelectorContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    gap: 7px;
    border-radius: 5px;
    
    img {
        width: 42px;
        height: auto;
    }

    h3 {
        width: 50%;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #000000;
        margin-bottom: 0;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #333333;
        margin-bottom: 0;
    }
}


 .previewFrame::-webkit-scrollbar {
    width: 30px;
}
 
 .previewFrame::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
.previewFrame::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}